import React from 'react'
import Header from '../components/header'

import layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return(
        <div>
            <Header/>
            <div className={layoutStyles.caixa}>
                {props.children}
            </div>
        </div>
    )
}

export default Layout